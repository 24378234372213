/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $( '.slider' ).each( function() {
          $container = $( this ).closest('.slider-container');
          $(this).slick({
            arrows: true,
            dots: false,
            slidesToShow: 2,
            prevArrow: '<button type="button" class="slick-prev btn btn-secondary btn-round"><i class="fa fa-arrow-left"></i></button>',
            nextArrow: '<button type="button" class="slick-next btn btn-secondary btn-round"><i class="fa fa-arrow-right"></i></button>',
            appendArrows: $container,
            responsive: [
              {
                breakpoint: 860,
                settings: {
                  slidesToShow: 1,
                }
              }
            ]
          });
        });

        $( '.slider-hero' ).each( function() {
          $container = $( this ).closest('.slider-container');
          $(this).slick({
            arrows: true,
            dots: true,
            slidesToShow: 1,
            centerMode: true,
            prevArrow: '<button type="button" class="slick-prev btn btn-secondary btn-round"><i class="fa fa-arrow-left"></i></button>',
            nextArrow: '<button type="button" class="slick-next btn btn-secondary btn-round"><i class="fa fa-arrow-right"></i></button>',
            appendArrows: $container,
            appendDots: $container.parent()
          });
        });

        $( '.slider-gallery' ).each( function() {
          $container = $( this ).closest('.slider-container');
          $(this).slick({
            arrows: true,
            dots: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 20000,
            prevArrow: '<button type="button" class="slick-prev btn btn-secondary btn-round"><i class="fa fa-arrow-left"></i></button>',
            nextArrow: '<button type="button" class="slick-next btn btn-secondary btn-round"><i class="fa fa-arrow-right"></i></button>',
            appendArrows: $container,
            appendDots: $container.parent(),
            responsive: [
              {
                breakpoint: 1200,
                settings: {
                  slidesToShow: 2,
                }
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 1,
                }
              },
            ]
          });
        });

        $('.menu-toggle').click(function(){
          $('.hamburger', this).toggleClass('is-active');
          $('.banner').toggleClass('is-active');
          $('.nav-wrapper').slideToggle(150);
        });

        if($('main.main > section:last-child').attr('class').includes('section-light')){
          $('footer').find('section:first-child').addClass('connect-section-light');
        }

        if($('.onpage-links').length) {
          var chapters = $('.entry-content').find('h2');
          var links = $('.onpage-links');

          chapters.each(function(index){
            var chapter = $(this);
            chapter.attr('id', 'chapter-' + index);
            var chapterId = chapter.attr('id');
            var chapterLink = $('<li scroll-to="#' + chapterId + '" class="chapter-link">' + chapter.text() + '</li>');
            links.append(chapterLink);
          });
          
          if(!chapters.length){
            $('.onpage-links').prev('p').remove();
            $('.onpage-links').remove();
          }
        }

        $('*[scroll-to]').click(function(){
          var target = $(this).attr('scroll-to');
          var extraOffset = $('.banner').height();
          if($('#wpadminbar').length){
            extraOffset += $('#wpadminbar').height();
          }
          extraOffset += 40;
          $('html, body').animate({
            scrollTop: $(target).offset().top - extraOffset
          }, 1000);
        });

        
        $("body > .wave-svg").clone().prependTo($('main > section, footer > section'));
        $("body > .wave-svg").clone().appendTo($('main > section.page-header:first-child:last-child'));

        $('footer section:first-child').find('.wave-svg').show();
        $('footer section:last-child').find('.wave-svg').show().addClass('bottom-wave');

        $('section').each(function(){
          var $section = $(this),
              $wave = $section.find('.wave-svg'),
              $prevSection = $section.prev();

          if($prevSection.length){
            var prevIsLight = $prevSection.hasClass('section-light'),
                prevIsHeader = $prevSection.hasClass('page-header');

            if($section.hasClass('section-light') && !$prevSection.hasClass('section-light')){
              $wave.show();
            }

            if(prevIsHeader || prevIsLight ){
              $wave.show();
              $wave.addClass('bottom-wave');
            }
          }else {
            if($section.hasClass('page-header')){
              $wave.show();
              if($section.find('.wave-svg:last-child').length){
                $section.find('.wave-svg:last-child').addClass('bottom-wave');
              }
            }
          }

        });

        $('.search-submit-icon').on('click',function(){
          $(this).next('input').trigger('click');
        });

        if($('article.berichttype-video').length){
          $article = $('article.berichttype-video');
          $article.closest('.row').find('.col-lg-3').addClass('order-1 offset-lg-1');
          $article.closest('.row').find('.col-lg-6').removeClass('col-lg-6 offset-lg-0').addClass('col-lg-10 offset-lg-1');
        }
        
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
